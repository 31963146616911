<template>
    <div class="col-12">
        <div class="mb-3">
            <label class="col-form-label">1. </label>
            <textarea class="form-control" :disabled="disabled" rows="4" v-model="model.texto_uno"></textarea>
        </div>
    </div>
    <div class="col-12">
        <div class="mb-3">
            <label class="col-form-label">2. </label>
            <textarea class="form-control" :disabled="disabled" rows="4" v-model="model.texto_dos"></textarea>
        </div>
    </div>
    <div class="col-12">
        <div class="mb-3">
            <label class="col-form-label">3. </label>
            <textarea class="form-control" :disabled="disabled" rows="4" v-model="model.texto_tres"></textarea>
        </div>
    </div>
    <div class="col-12">
        <div class="mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">OD</th>
                        <th class="text-center">Músculo</th>
                        <th class="text-center">OD</th>
                        <th class="text-center">VR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_uno"></td>
                        <td>Recto Medial</td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_uno"></td>
                        <td>2.5-3.0</td>
                    </tr>
                    <tr>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_dos"></td>
                        <td>Complejo Superior</td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_dos"></td>
                        <td>2.8-3.8</td>
                    </tr>
                    <tr>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_tres"></td>
                        <td>Recto Lateral</td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_tres"></td>
                        <td>2.5-3.0</td>
                    </tr>
                    <tr>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_cuatro"></td>
                        <td>Recto Inferior</td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_cuatro"></td>
                        <td>2.7-3.7</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { defineProps, defineModel } from 'vue';

const model = defineModel();
defineProps({
    disabled: { type: Boolean, default: false },
});
</script>

<style></style>
